import axios from 'axios';


const baseURL = 'https://ccc-server.demo.ewubd.edu'; // Replace with your server URL
// const baseURL = 'http://localhost:8000'; // Replace with your server URL


const axiosClient = axios.create({
  baseURL,
});

axiosClient.interceptors.request.use(config => {
  const token = localStorage.getItem('awesome');

  // Check if token exists and add it to the Authorization header
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});


export default axiosClient;


// import Form from "vform";
// // import axiosClient from '../../axios_client';


// export default class CustomForm extends Form {
//   constructor(data) {
//     super(data);
//     this.setSanctumToken();
//   }

//   setSanctumToken() {
//     const token = localStorage.getItem('authToken');
//     if (token) {
//       this.headers['Authorization'] = `Bearer ${token}`;
//     }
//   }

//   submit(method, url) {
//     this.setSanctumToken();
//     return super.submit(method, url);
//   }
// }
